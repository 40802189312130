<template>
  <div class="col s12 m6 l4 center grey z-depth-2 square">
      <h1><i class = "grey-text text-darken-2" :class="icon"></i></h1>
      <h5 class = "grey-text text-darken-2">{{ text }}</h5>
  </div>
</template>

<script>
export default {
    props: ['icon', 'text']
}
</script>

<style scoped>
    .square {
        margin-top: 20px;
        cursor: pointer;
    }
    
</style>