<template>
  <div class="container">
    <div class="row">
      <Title text="Panel de Control" />
      <AdminSquare icon="fas fa-fist-raised" text="Disputas" @click="handleClick('ManageDisputes')"/>
      <AdminSquare icon="fas fa-comment-dots" text="Chat" @click="handleClick('ManageChats')"/>
      <AdminSquare icon="fas fa-random" text="Escrows" @click="handleClick('ManageEscrows')"/>
      <AdminSquare icon="fas fa-envelope" text="Emails" @click="handleClick('ManageUsers')"/>
      <AdminSquare icon="fas fa-wallet" text="Monederos" @click="handleClick('SeeWallet')"/>
    </div>
  </div>
</template>

<script>
import AdminSquare from '@/components/Admin/AdminSquare'
import Title from '@/components/Admin/Title'
import { useRouter } from 'vue-router'
export default {
  components: { AdminSquare, Title },
  setup(){
    
    const router = useRouter();

    const handleClick = (destination) => {
      router.push({name: destination})
    }

    return { handleClick };
  }
}
</script>

<style>
  
</style>